<template>
  <el-card class="sm:px-0">
    <ul v-if="productsList.length > 0" class="-my-20">
      <li
        v-for="(product, idx) in productsList"
        :key="idx"
        class="bg-white hover:bg-grey-50 py-16 px-16 sm:px-24 border-b border-transparent cursor-pointer"
        :class="{ 'border-hr': idx !== productsList.length - 1, 'bg-grey-50': product.isMarked }"
        @click="onRowClick(product)"
      >
        <div class="flex flex-1 items-center justify-between mb-8">
          <div class="flex items-center">
            <p class="text-green-500 font-medium">{{ product.title }}</p>
          </div>

          <el-status :status="getStatus(product)" />
        </div>

        <div class="sm:flex sm:items-center sm:justify-between">
          <div class="flex items-center mr-24 mb-8 sm:mb-0">
            <span class="icon-calendar flex justify-start text-2lg text-grey-300" />
            <p class="ml-6 text-grey-400">Publiseret: {{ isPublish(product) }}</p>
          </div>

          <div class="flex items-center ml-32 sm:ml-0">
            <span class="icon-user flex justify-start text-2lg text-grey-300" />
            <span class="ml-6 inline-block text-grey-400">{{ product.data.vendor }}</span>
          </div>
        </div>
      </li>
    </ul>
  </el-card>
</template>

<script>
const ElCard = () => import('@/components/shared/ELCard')
const ElStatus = () => import('@/components/shared/ELStatus')

export default {
  name: 'ProductsList',

  components: { ElCard, ElStatus },

  props: {
    productsList: {
      type: Array,
      default: () => []
    },

    isRowClick: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    onRowClick (product) {
      if (this.isRowClick) {
        return this.$router.push({ name: 'Product', params: { productId: product.id } })
      }
      if (product.fulfillmentStatus !== 'Fulfilled') {
        product.isMarked = !product.isMarked
      }
    },

    isPublish (product) {
      return product.data.published_at !== null && new Date(product.data.published_at) <= Date.now() ? 'Ja' : 'Nej'
    },

    getStatus (product) {
      const metafield = product.data.metafields.find(el => el.namespace === 'dtails_pim')
      if (typeof metafield.value === 'string') metafield.value = JSON.parse(metafield.value)
      return metafield.value.workflowStatus
    }
  }
}
</script>
